@use '@styles/_partials' as *;
.section {
  margin-bottom: 2.5rem;

  @include respond-to($sm) {
    margin-bottom: 3rem;
  }

  @include respond-to($lg) {
    margin-bottom: 4rem;
  }
}

.bgImgContainer {
  position: relative;
  display: block;
  min-height: 360px;
  overflow: hidden;

  @include respond-to($sm) {
    min-height: 420px;
  }

  @include respond-to($md) {
    min-height: 460px;
  }

  @include respond-to($lg) {
    min-height: 480px;
  }

  @include respond-to($xl) {
    min-height: 520px;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
}

.offsetContainer {
  position: relative;
  z-index: 10;
  margin-top: pxToRem(-75px);
  background: $white;

  @include respond-to($sm) {
    margin-top: pxToRem(-80px);
  }

  @include respond-to($lg) {
    margin-top: pxToRem(-95px);
  }
}
